import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import Sidebard2024 from '../HealthAndWellnesClub/Sidebard2024';
import Archive2023 from '../HealthAndWellnesClub/Archive2023';

const ConditioningBoys2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioningboys/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioningboys/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioningboys/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioningboys/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1.5,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                SOCIAL CONDITIONING FOR BOYS
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    CLASS: 4 – 5(Boys)    Date: Wednesday, 5 March 2025
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                A captivating and enlightening workshop on Social Conditioning was held for the young boys of Classes 4 and 5 on Wednesday, 5th March 2025. The session delved into the profound importance of adhering to the school’s guiding principles, while also imparting the essential skill of anger management. The discourse highlighted how the mindful observance of rules cultivates a harmonious and respectful environment, benefiting not just the individual, but the entire community. 
                                    <br></br>
                                    The boys were gently introduced to a range of practical techniques for mastering their emotions, empowering them to navigate conflicts with poise, patience and maturity. Through engaging and thought-provoking discussions, the students not only absorbed these invaluable lessons but also made a heartfelt commitment to put them into practice, thus ensuring a more peaceful and positive atmosphere in their daily school life.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h4'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                              The path to maturity is paved with patience, understanding, and the ability to manage conflict with grace.
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebard2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2023 />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ConditioningBoys2024;